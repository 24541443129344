<template>
  <div class="flex">
    <FormItem>
      <Checkbox v-model="demo" :disabled="disabled">
        {{ $t("WILL_SERVE_FOR_A_DEMO") }}?
      </Checkbox>
    </FormItem>
    <div class="flex-grow ivu-form-item-label">
      <span class="text-gray-400">
        {{
          $t(
            "A_DEMO_ORGANIZATION_WILL_NOT_BE_ABLE_TO_PERFORM_ANY_PAID_ACTION_LIKE_SENDING_EMAILS"
          )
        }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  computed: {
    demo: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style scoped></style>
