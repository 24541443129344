<template>
  <Error :error="error">
    <div class="flex">
      <label class="ivu-form-item-label">{{ $t("NAME") }}</label>
      <div class="flex-grow ivu-form-item-label">
        <span class="text-gray-400">
          {{ $t("GENERALLY_THE_NAME_OF_THE_COMPANY") }}
        </span>
      </div>
    </div>
    <ValidationProvider
      slim
      :name="`&quot;${$t('NAME')}&quot;`"
      rules="required"
      immediate
      v-slot="{ pristine, errors }"
    >
      <FormItem
        :error="
          pristine
            ? ''
            : alreadyExist
            ? $t('THIS_ORGANIZATION_ALREADY_EXIST')
            : errors[0]
        "
      >
        <Input
          :value="value"
          @input="value => $emit('input', value)"
          :placeholder="$t('ORGANIZATION_NAME')"
          :disabled="disabled"
          clearable
        />
      </FormItem>
    </ValidationProvider>
  </Error>
</template>

<script>
import { ValidationProvider } from "vee-validate-full";
import tenantsQuery from "./queries/tenants.gql";
import Error from "@/components/Error/Error.vue";
import { escapeRegExp, snakeCase } from "lodash";

export default {
  components: { ValidationProvider, Error },
  props: {
    value: { type: String, default: "" },
    disabled: { type: Boolean, default: false }
  },
  data: ({ value }) => ({ tenants: [], error: null }),
  apollo: {
    tenants: {
      query: tenantsQuery,
      update: ({ tenants: { entries = [] } = {} }) =>
        entries
          .filter(({ name }) => name !== "delight")
          .map(({ name, displayName, ...rest }) => ({
            ...rest,
            name,
            displayName: displayName || name
          })),
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(networkError || graphQLErrors);
      }
    }
  },
  computed: {
    alreadyExist: vm => {
      const { value, tenants } = vm;
      const alreadyExist =
        !!value &&
        tenants.some(({ displayName }) =>
          RegExp(`^${escapeRegExp(value)}$`, "i").test(displayName)
        );
      vm.$emit("isValid", !alreadyExist);

      return alreadyExist;
    }
  }
};
</script>
