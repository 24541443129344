<template>
  <ValidationObserver v-slot="{ invalid }" class="flex flex-col">
    <h1 class="my-5 text-lg font-bold tracking-wide">
      {{ $t("ONBOARD_A_NEW_ORGANIZATION") }}
    </h1>
    <Card dis-hover class="my-5 flex-grow">
      <Error :error="error">
        <Form label-position="top" @submit.native.prevent>
          <DisplayName
            v-model="displayName"
            :disabled="loading"
            @isValid="isValid = $event"
          />
          <div>
            <h1 class="pb-3">{{ $t("CAN_I_HAVE_SOME_EXTRA_INFORMATION") }}</h1>
            <DemoCheckbox
              v-model="demo"
              :disabled="loading"
              @input="handleCheckDemo"
            />
            <SelectQlikApplications
              v-model="selectedQlikApps"
              :disabled="loading"
            />

            <SetExpirationDate v-model="expireInDays" :disabled="loading" />
          </div>
          <FormItem class="mb-0 flex justify-center">
            <Button
              type="primary"
              class=""
              :disabled="invalid || !isValid"
              :loading="loading"
              @click="validate"
            >
              {{ $t("CREATE") }}
            </Button>
          </FormItem>
        </Form>
      </Error>
    </Card>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate-full";
import DisplayName from "./components/DisplayName/DisplayName.vue";
import DemoCheckbox from "./components/DemoCheckbox/DemoCheckbox.vue";
import SelectQlikApplications from "./components/SelectQlikApplications/SelectQlikApplications.vue";
import SetExpirationDate from "./components/SetExpirationDate/SetExpirationDate.vue";
import onboardOrganization from "./queries/onboardOrganization.gql";
import qlikAppsQuery from "./queries/qlikApps.gql";
import tenantsQuery from "@/Organizations/Organizations/queries/tenants.gql";
import Error from "@/components/Error/Error.vue";
import { snakeCase } from "lodash";

export default {
  components: {
    ValidationObserver,
    DisplayName,
    DemoCheckbox,
    SelectQlikApplications,
    SetExpirationDate,
    Error
  },
  data: () => ({
    loading: false,
    error: null,
    displayName: "",
    isValid: false,
    demo: false,
    qlikApps: [],
    selectedQlikApps: [],
    expireInDays: 0
  }),
  apollo: {
    qlikApps: {
      query: qlikAppsQuery,
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(networkError || graphQLErrors);
      }
    }
  },
  methods: {
    handleCheckDemo(checked) {
      if (!checked || this.selectedQlikApps[0]) return;
      const producerApp = this.qlikApps.find(
        ({ name }) => name === "odag_producer"
      );
      this.selectedQlikApps = [producerApp];
    },
    validate() {
      this.loading = true;
      const { displayName, expireInDays, demo, selectedQlikApps } = this;
      const qlikAppIds = selectedQlikApps.map(({ id }) => id);

      this.$apollo
        .mutate({
          mutation: onboardOrganization,
          variables: {
            name: snakeCase(displayName),
            displayName,
            expireInDays,
            creationMode: demo ? "DEMO" : "STANDARD",
            qlikAppIds
          },
          refetchQueries: [
            {
              query: tenantsQuery,
              variables: { search: "", page: { current: 1, size: 10 } }
            }
          ]
        })
        .then(() => {
          this.$Notice.success({
            title: this.$t("SUCCESS"),
            duration: 1
          });
          this.$router.push({ path: "/organizations" });
        })
        .catch(({ graphQLErrors, networkError }) =>
          this.$Notice.error({
            title: this.$t("ERROR"),
            desc: JSON.stringify(networkError || graphQLErrors),
            duration: 0
          })
        )
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped></style>
