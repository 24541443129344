<template>
  <div>
    <div>
      <FormItem class="mb-0">
        <Checkbox
          v-model="willExpire"
          :disabled="disabled"
          @on-change="checked => (daysBeforeExpiration = checked ? 30 : 0)"
        >
          {{ $t("SET_AN_EXPIRATION_PERIOD") }}
        </Checkbox>
      </FormItem>
    </div>
    <div class="flex">
      <label class="ivu-form-item-label">{{ $t("EXPIRES_AFTER") }}</label>
      <div class="flex-grow ivu-form-item-label">
        <span class="text-gray-400">
          {{ $t("NUMBER_OF_DAYS_BEFORE_EXPIRATION") }}
        </span>
      </div>
    </div>
    <ValidationProvider
      slim
      :name="`&quot;${$t('EXPIRES_AFTER')}&quot;`"
      :rules="
        newOrganization && willExpire ? 'required|integer|min_value:1' : ''
      "
      immediate
      v-slot="{ pristine, errors }"
    >
      <FormItem :error="pristine ? '' : errors[0]">
        <Input
          v-model="daysBeforeExpiration"
          type="number"
          number
          placeholder="30"
          :disabled="!willExpire || disabled"
        >
          <template #append>{{ $t("DAYS") }}</template>
        </Input>
      </FormItem>
    </ValidationProvider>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate-full";

export default {
  components: { ValidationProvider },
  props: {
    value: { type: [Number, String], default: 0 },
    newOrganization: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return { willExpire: !!this.value };
  },
  computed: {
    daysBeforeExpiration: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style scoped></style>
