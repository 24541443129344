var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Error',{attrs:{"error":_vm.error}},[_c('div',{staticClass:"flex"},[_c('label',{staticClass:"ivu-form-item-label"},[_vm._v(_vm._s(_vm.$t("NAME")))]),_c('div',{staticClass:"flex-grow ivu-form-item-label"},[_c('span',{staticClass:"text-gray-400"},[_vm._v(" "+_vm._s(_vm.$t("GENERALLY_THE_NAME_OF_THE_COMPANY"))+" ")])])]),_c('ValidationProvider',{attrs:{"slim":"","name":("\"" + (_vm.$t('NAME')) + "\""),"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var pristine = ref.pristine;
var errors = ref.errors;
return [_c('FormItem',{attrs:{"error":pristine
          ? ''
          : _vm.alreadyExist
          ? _vm.$t('THIS_ORGANIZATION_ALREADY_EXIST')
          : errors[0]}},[_c('Input',{attrs:{"value":_vm.value,"placeholder":_vm.$t('ORGANIZATION_NAME'),"disabled":_vm.disabled,"clearable":""},on:{"input":function (value) { return _vm.$emit('input', value); }}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }